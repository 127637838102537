import { IAccount } from 'services/account';
import { FormationsPlan, ProgressTrackerStatus } from 'models/account';

export const isAccount1120sOnly = (account?: IAccount): boolean =>
  account?.formationsPlan === FormationsPlan.TaxOnly;

export const isAccountBusinessInABox = (account?: IAccount): boolean =>
  account?.formationsPlan === FormationsPlan.BIB;

export const isAccountFullSuite = (account?: IAccount): boolean =>
  account?.formationsPlan == null ||
  account?.formationsPlan === FormationsPlan.FullSuite;

export const isAccountPayrollEnabled = (account?: IAccount): boolean =>
  !account?.disabledPayroll;

const getStates = (state: any, isFromIncorporated: boolean = false) => {
  let states;
  if (isFromIncorporated) {
    states = Object.keys(state);
  } else {
    states = Object.keys(state.steps);
  }
  return states;
};

const isStatusCompleted = (
  state: any,
  item: string,
  isFromIncorporated: boolean = false,
) => {
  if (isFromIncorporated) {
    return state[item]?.status === ProgressTrackerStatus.Completed;
  }
  return state.steps[item]?.status === ProgressTrackerStatus.Completed;
};

export const getCompletedCount = (
  state: any,
  isFromIncorporated: boolean = false,
) => {
  const states = getStates(state, isFromIncorporated);
  if (states.length > 0) {
    return states.filter((item) =>
      isStatusCompleted(state, item, isFromIncorporated),
    ).length;
  }
  return 0;
};

export const getFirstNoCompleted = (
  state: any,
  isFromIncorporated: boolean = false,
) => {
  const states = getStates(state, isFromIncorporated);
  if (states.length > 0) {
    return states.findIndex(
      (item) => !isStatusCompleted(state, item, isFromIncorporated),
    );
  }
  return -1;
};
