import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from 'react-query';
import {
  createCompanyByUserId,
  createOwner,
  deleteOwner,
  getCompanyById,
  getCompanyByUserId,
  getCompanyCoverLetter,
  getCompanySetupState,
  getOwner,
  getOwners,
  initIncorporationStatus,
  notifyIncorporationStatus,
  setCompanySetupState,
  updateCompanyById,
  updateCompanyCoverLetter,
  updateIncorporationStatus,
  updateOwner,
} from 'services/companies';
import { omit } from 'lodash';
import {
  Company,
  CompanySetupState,
  CreateOwnerData,
  Owner,
  UpdateCompanySetupState,
  UpdateIncorporationStatusSetting,
} from 'models/company';
import { useCurrentUser } from './useUser';
import { useCurrentAccount } from './useAccounts';

export const useCompany = (
  id?: string,
  queryProp?: UseQueryOptions<Company>,
) => {
  const { data, ...rest } = useQuery<Company>(
    ['getCompany', id],
    () => getCompanyById(id!),
    { ...queryProp, enabled: !!id },
  );
  return {
    company: data,
    ...rest,
  };
};

export const useCompanyByUserId = (
  id?: string,
  queryProps?: UseQueryOptions<Company | undefined>,
) => {
  const { data, ...rest } = useQuery<Company | undefined, unknown>(
    ['company/user', 'userid', id],
    () =>
      getCompanyByUserId(id).then((companies) => {
        if (companies.length > 0) {
          return companies[0];
        }
        return undefined;
      }),
    { ...queryProps, enabled: Boolean(id) },
  );

  return {
    company: data,
    ...rest,
  };
};

export const useCurrentCompany = () => {
  const { currentUser } = useCurrentUser();
  const companyId = currentUser?.companyId;
  const { data, ...rest } = useQuery<Company | undefined>(
    ['getCompany', companyId],
    () => {
      if (!companyId) {
        return Promise.resolve(undefined);
      }
      return getCompanyById(companyId!);
    },
  );
  return {
    currentCompany: data,
    ...rest,
  };
};

interface UseUpdateCompanyVariables {
  id: string;
  data: any;
}
export const useUpdateCompany = (
  mutationProps?: UseMutationOptions<
    unknown,
    unknown,
    UseUpdateCompanyVariables
  >,
) =>
  useMutation(
    ({ id, data }: UseUpdateCompanyVariables) => updateCompanyById(id, data),
    mutationProps,
  );

interface UseCreateCompanyVariables {
  userId: string;
  data: any;
}
export const useCreateCompany = (
  mutationProps?: UseMutationOptions<
    unknown,
    unknown,
    UseCreateCompanyVariables
  >,
) =>
  useMutation(
    ({ userId, data }: UseCreateCompanyVariables) =>
      createCompanyByUserId(userId, data),
    mutationProps,
  );

export const useUpdateIncorporationStatus = (companyId: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    (data: UpdateIncorporationStatusSetting) =>
      updateIncorporationStatus(companyId, data),
  );
  return {
    updateIncorporationStatus: mutate,
    updateIncorporationStatusAsync: mutateAsync,
    ...rest,
  };
};

export const useInitIncorporationStatus = (companyId: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation(() =>
    initIncorporationStatus(companyId),
  );
  return {
    initIncorporationStatus: mutate,
    initIncorporationStatusAsync: mutateAsync,
    ...rest,
  };
};

export type CoverLetterData = {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  companyId: string;
  title: string;
  body: string;
  bankInfo: string;
};
interface UseUpcateCoverLetterVariables {
  companyId: string;
  data: CoverLetterData;
}
export const useUpdateCoverLetter = (
  mutationProps?: UseMutationOptions<
    unknown,
    unknown,
    UseUpcateCoverLetterVariables
  >,
) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    ({ companyId, data }: UseUpcateCoverLetterVariables) =>
      updateCompanyCoverLetter(companyId, data),
    mutationProps,
  );
  return {
    updateCoverLetter: mutate,
    updateCoverLetterAsync: mutateAsync,
    ...rest,
  };
};

export const useCurrentCoverLetter = () => {
  const { currentUser } = useCurrentUser();
  const companyId = currentUser?.companyId;
  const { data, ...rest } = useQuery<CoverLetterData | null | undefined>(
    ['companyCoverLetter', companyId],
    () => getCompanyCoverLetter(companyId!),
    { enabled: !!companyId },
  );
  return {
    coverLetter: data,
    ...rest,
  };
};

export const useNotifyIncorporationStatus = (companyId: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation(() =>
    notifyIncorporationStatus(companyId),
  );
  return {
    notifyIncorporationStatus: mutate,
    notifyIncorporationStatusAsync: mutateAsync,
    ...rest,
  };
};
interface UseCreateOwnerVariables {
  companyId?: string;
  data: CreateOwnerData;
}
export const useCreateOwner = (
  mutationProps?: UseMutationOptions<unknown, unknown, UseCreateOwnerVariables>,
) =>
  useMutation(
    ({ companyId, data }: UseCreateOwnerVariables) =>
      createOwner(companyId!, data),
    mutationProps,
  );

interface UseUpdateOwnerVariables {
  ownerId: string;
  companyId: string;
  data: Partial<Owner>;
}

export const useUpdateOwner = (
  mutationProps?: UseMutationOptions<unknown, unknown, UseUpdateOwnerVariables>,
) =>
  useMutation(
    ({ ownerId, companyId, data }: UseUpdateOwnerVariables) =>
      updateOwner(ownerId, companyId, data),
    mutationProps,
  );

interface UseDeleteOwnerVariables {
  ownerId: string;
  companyId: string;
}

export const useDeleteOwner = (
  mutationProps?: UseMutationOptions<unknown, unknown, UseDeleteOwnerVariables>,
) =>
  useMutation(
    ({ ownerId, companyId }: UseDeleteOwnerVariables) =>
      deleteOwner(ownerId, companyId),
    mutationProps,
  );

export const useOwners = (companyId?: string) => {
  const { data, ...rest } = useQuery<Owner[] | null | undefined>(
    ['companyOwners', companyId],
    () => getOwners(companyId!),
    { enabled: !!companyId },
  );
  return {
    owners: data,
    ...rest,
  };
};

export const useOwner = (
  ownerId?: string,
  companyId?: string,
  viewSSN?: boolean,
) => {
  const { data, ...rest } = useQuery<Owner | null | undefined>(
    ['companyOwner', ownerId, companyId],
    () => getOwner(ownerId!, companyId!, viewSSN),
    { enabled: !!ownerId && !!companyId },
  );
  return {
    owner: data,
    ...rest,
  };
};

export const useCompanySetupState = (companyId?: string) => {
  const { data, ...rest } = useQuery<CompanySetupState | undefined>(
    ['companySetupState', companyId],
    () => getCompanySetupState(companyId!),
    { enabled: !!companyId },
  );
  return {
    companySetupState: data,
    ...rest,
  };
};

export const useUpdateCompanySetupState = (companyId?: string) => {
  const { mutate, mutateAsync, ...rest } = useMutation(
    (data: Partial<UpdateCompanySetupState>) => {
      if (!companyId) {
        return Promise.reject(new Error('Company ID is required'));
      }
      return setCompanySetupState(companyId!, data);
    },
  );

  return {
    updateCompanySetupState: mutate,
    updateCompanySetupStateAsync: mutateAsync,
    ...rest,
  };
};

const stripCompanySetupState = (
  state: Partial<CompanySetupState>,
): Partial<CompanySetupState> => omit(state, ['id', 'createdAt', 'updatedAt']);

export const useUpdateCompanySetupStateAsync = () => {
  const { currentAccount } = useCurrentAccount();
  const { currentCompany } = useCurrentCompany();
  const { companySetupState } = useCompanySetupState(currentCompany?.id);
  const { updateCompanySetupStateAsync, ...rest } = useUpdateCompanySetupState(
    currentCompany?.id,
  );
  const mutateAsync = async (data: Partial<UpdateCompanySetupState>) => {
    if (companySetupState) {
      return updateCompanySetupStateAsync({
        ...stripCompanySetupState(companySetupState),
        ...data,
      });
    }
    return updateCompanySetupStateAsync({
      accountId: currentAccount?.id,
      companyId: currentCompany?.id,
      ...data,
    });
  };
  return {
    updateCompanySetupStateAsync: mutateAsync,
    ...rest,
  };
};
